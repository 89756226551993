/** Bluedesk base CSS **/

/** Fonts **/
body {
  background-color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

button {
  background: transparent;
  padding: 7px 15px;
  border: 0 solid #fff;
}

input {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 20px auto;
}

/** CSS **/
.app_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.flex_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.logo_wrapper {
  padding: 0px 20px 20px;
  margin: 0px auto 0px;
  text-align: center;
  max-width: 80%;
}

.flags_wrapper {
  text-align: center;
}

.flags_wrapper > .flag_link {
  display: inline-block;
  max-width: 30%;
  margin: 1%;
  padding: 10px;
  box-sizing: border-box;
}

.flags_wrapper > .flag_link > img {
  display: block;
  width: 100%;
  max-width: 150px;
}

img.bluemessage_large_logo {
  max-width: 80%;
}

.fake_form {
  text-align: center;
  /* max-width: 780px; */
  margin: 0 auto;
}

.fake_form h2 {
  font-size: 1.8em;
}

.fake_form p {
  padding: 20px;
  font-size: 120%;
  max-width: 650px;
}

.qr_code {
  text-align: right;
  padding: 10px;
  width: 40%;
  float: left;
  position: relative;
}
input.text_input {
  margin: 10px auto;
  border-bottom: 1px solid #eee;
  width: 200px;
  text-align: center;
  font-size: 90%;
  /* position: absolute; */
  display: block;
  position: relative;
}

.text_input:after {
  content: "test";
  position: relative;
  top: 0;
  left: 0;
  width: 20px;
  background: black;
  height: 50px;
}

.input_wrapper {
  /*display: none;*/
}

.confirm {
  padding: 20px;
  text-align: center;
}

.separator {
  float: left;
  height: 100px;
  width: 10%;
  margin: 20px 0 20px auto;
  border-left: 1px solid #ddd;
  position: relative;
  text-align: center;
}

.qr_code img {
  max-width: 120px;
  position: relative;
}

.clearfix {
  float: none;
  clear: both;
  width: 100%;
  height: 1px;
}

.login_input_wrapper {
  width: 240px;
  float: none;
  padding: 30px 15px;
  margin: 20px auto;
}

.text_input_wrapper {
  /* width: 40%; */
  float: left;
  padding: 27px 15px;
  position: relative;
  margin: 0;
}

.fake_form_subwrapper {
  text-align: center;
  /* width: 75%; */
  display: flex;
  justify-content: space-between;
  margin: 0px auto 30px;
  min-width: 675px;
}

span.separator_or {
  position: absolute;
  left: -100%;
  background: white;
  top: 30px;
  padding: 10px;
  color: #999;
  right: 0;
  width: 50px;
  margin: 0 auto;
}

.text_input_wrapper small {
  color: #aaa;
  text-align: center;
  width: 100%;
  display: block;
  position: relative;
  /* top: 50px; */
  /* left: 20px; */
}

.loader_repeat img {
  max-width: 40px;
  position: relative;
  top: 15px;
}

.loader_repeat {
  color: #999;
}

div#login_form {
  text-align: center;
  margin: 20px auto;
  padding: 50px;
  max-width: 600px;
}

.return_to_languages {
  background: transparent;
  color: #000;
  margin: 0 7.5% 30px auto;
  position: relative;
  left: -5px;
  display: block;
  border: 0;
}

.welcome_confirm {
  padding: 10px 20px;
  position: relative;
  display: block;
  width: 220px;
  border: 1px solid #777;
  margin: 28px 0;
}

#disconnected_wrapper {
  font-size: 22px;
  margin: 0 auto;
  padding: 20px;
  height: 100%;
  max-width: 100%;
}

#connected_wrapper {
  max-width: 100%;
  height: 100%;
}
.qrcode_only .qr_code {
  width: 100%;
  float: none;
  text-align: center;
}

.qrcode_only .separator,
.qrcode_only .text_input_wrapper {
  display: none;
}

.fake_form_subwrapper.qrcode_only {
  justify-content: center;
}

.qrcode_only .return_to_languages {
  margin: 10px auto;
}

.qrcode_only .qr_code img {
  max-width: 300px;
}

@media (max-width: 720px) {
  .separator,
  .hidden-xs {
    display: none;
  }

  .logo_wrapper {
    padding: 50px 10px;
  }

  .flags_wrapper > a {
    width: 45%;
    max-width: 45%;
  }

  .flags_wrapper > a > img {
    max-width: 100%;
  }

  .fake_form > * {
    max-width: 100%;
    float: none;
    margin: 10px auto;
    min-width: auto;
  }

  .fake_form p {
    padding: 10px;
    font-size: 90%;
  }

  .fake_form h2 {
    font-size: 1.4em;
    padding: 10px;
  }

  .input_wrapper {
    max-width: 100%;
  }

  body {
    height: auto;
    overflow-y: auto;
  }

  .flex_container {
    display: block;
    height: auto;
  }

  .text_input_wrapper {
    width: 50%;
    position: relative;
    left: 0;
    padding: 0;
    margin: 0 auto;
  }
  input.text_input {
    padding: 10px;
    margin: 20px auto 0px;
    max-width: 100%;
  }
  .qr_code {
    margin-left: auto;
    padding: 20px;
    width: 40%;
  }

  .qr_code img {
    max-width: 80px;
  }
  .return_to_languages {
    left: 0;
    padding: 5px 10px;
  }
}
