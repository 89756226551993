.table_page_row input.text_input {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 150%;
  width: 100%;
  line-height: 2.5em;
  border-bottom: 1px solid #666;
}

.table_page_row {
  border: 1px solid #f7f7f7;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  max-width: 90%;
  margin: 5px auto 0;
  /* width: 100%; */
}

.clearfix {
  height: 1px;
  width: 100%;
  float: none;
  clear: both;
}

div[class^="table_page_column"],
div[class^="table_page_content"] {
  /*max-width: 50%;*/
  box-sizing: border-box;
  padding: 1em;
  margin: 1em auto;
}

.table_page_thead,
.table_page_tbody {
  clear: both;
}

.table_page_thead {
  display: flex;
  justify-content: space-around;
  max-width: 580px;
  margin: 5px auto;
  font-weight: bold;
}

.table_column {
  padding: 1em;
}

canvas.signature_input {
  border: 1px solid #666;
}

@media (max-width: 550px) {
  .table_page_row {
    max-width: 100%;
    border: 0px solid #f7f7f7;
    border-top: 1px solid #f7f7f7;
    padding: 5px;
    display: block;
    margin: 5px auto 0;
  }

  .table_page_row input.text_input {
    font-size: 1em;
  }

  div[class^="table_page_column"],
  div[class^="table_page_content"] {
    margin: 0;
    padding: 3px;
  }

  canvas.signature_input {
    /*width: 100%;*/
    margin: 10px 0;
  }

  .field_wrapper.signature_field_wrapper {
    float: none;
  }

  .document_page .field_wrapper {
    min-width: auto;
    width: 100%;
    float: none !important;
  }
}
