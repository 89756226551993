#document_signer {
  height: 100%;
  position: relative;
}

.document_signer_icon_wrapper {
  margin: 50px 0 0 auto;
  width: 200px;
  height: 200px;
  text-align: center;
  color: #aaa;
}

.document_signer_icon_wrapper a {
  text-decoration: none;
  color: #aaa;
}

.access_reserved_wrapper {
  text-align: center;
  max-width: 500px;
  margin: 20px auto;
}

.document_signer_wrapper {
  padding: 10px;
}

.document_signer_wrapper > * {
  text-align: center;
}

.brand_link {
  max-width: 45%;
  margin: 1%;
  padding: 1%;
  box-sizing: border-box;
}

.brands_wrapper {
  display: flex;
  justify-content: center;
}
.brand_link img {
  max-width: 200px;
  width: 100%;
}
.documents_wrapper {
  display: flex;
  justify-content: center;
}

.document_link {
  text-transform: uppercase;
  font-size: 120%;
  text-align: center;
  font-weight: bold;
  margin: 2%;
}

.access_reserved_wrapper input {
  -webkit-text-security: disc;
}
button.document_page_button {
  border: 1px solid #777;
}

button.table_page_add_button {
  border: 1px solid #eee;
  background: black;
  color: white;
  margin: 10px auto;
}
