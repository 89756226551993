.document_page .content_field_wrapper {
  max-height: 60vh;
  overflow-y: auto;
  max-width: 90%;
  margin: 2vh auto;
  text-align: left;
}

.document_page .content_field_wrapper table {
  margin: 10px auto;
  width: 100%;
}

.document_page .field_wrapper {
  float: left;
  min-width: 48%;
  margin: 1%;
  text-align: left;
}

.field_wrapper.checkbox_field_wrapper {
  clear: both;
  width: 100%;
}

.document_page .field_wrapper label {
  margin: 25px;
  width: 80%;
}

.document_page .field_wrapper.checkbox_field_wrapper label {
  float: right;
  margin: 0 5px 25px;
}

.document_page input {
  border: 0;
  margin: 10px 5px 10px 25px;
  border-bottom: 1px solid #eee;
  text-align: left;
  font-size: 90%;
  display: block;
  position: relative;
}

.field_subwrapper.checkbox_field_subwrapper input {
  margin: 5px 10px 0 auto;
  text-align: right;
}

.document_page .clearfix {
  clear: both;
  width: 100%;
  height: 1px;
  float: none;
}

.document_page_subwrapper.input_content_page {
  max-width: 580px;
  margin: 10px auto;
  padding: 20px 0;
}

.document_page_subwrapper.wysiwyg_content_page {
  /* max-width: 780px; */
  margin: 10px auto;
}

.document_page .field_wrapper.content_field_wrapper {
  float: none;
  margin: 20px auto;
}

.field_wrapper.title_field_wrapper,
.field_wrapper.subtitle_field_wrapper,
.field_wrapper.subsubtitle_field_wrapper {
  clear: both;
  float: none;
  width: 100%;
}

.page_has_error {
  max-width: 400px;
  margin: 10px auto;
  padding: 1em;
  background: #f5f5f5;
  text-align: justify;
}

input.pincode-input-text[value] {
  font-size: large;
  background: #aaa !important;
  color: #aaa !important;
}

input.pincode-input-text[value=""] {
  background: white !important;
}
