.field_subwrapper.image_field_subwrapper img {
  max-width: 100%;
  max-height: 70vh;
  margin: 20px auto;
  display: block;
}

.sigCanvas {
  border: 1px solid #eee;
}

.field_subwrapper input[error="true"] {
  border: 1px solid red;
}

.field_wrapper.image_field_wrapper {
  text-align: center;
  width: 100%;
  float: none;
}

.field_wrapper.title_field_wrapper,
.field_wrapper.subtitle_field_wrapper,
.field_wrapper.subsubtitle_field_wrapper {
  float: none;
  clear: both;
}

.field_subwrapper.checkbox_field_subwrapper {
  float: left;
  position: relative;
  width: 18%;
}

.field_subwrapper.select_field_subwrapper select {
  border: 0;
  margin: 10px 5px 10px 25px;
  border-bottom: 1px solid #eee;
  text-align: left;
  font-size: 90%;
  display: block;
  position: relative;
  padding: 10px;
}
